import { fromUnixTime, isBefore } from 'date-fns';

const KEY_USER = 'YOUR_KEY_USER';
const KEY_USER_DATA = 'YOUR_KEY_USER_DATA';

const KEY_EXPIRATION = 'YOUR_KEY_EXPIRATION';
const KEY_NOTIFICATION = 'YOUR_KEY_NOTIFICATION';

export const setUserToken = (token: string): void =>
  localStorage.setItem(KEY_USER, token);

export const getUserToken = (): string | null => localStorage.getItem(KEY_USER);

export const removeUserToken = (): void => localStorage.removeItem(KEY_USER);

export const setUserData = (data: any): void =>
  localStorage.setItem(KEY_USER_DATA, JSON.stringify(data));

export const getUserData = (): any =>
  JSON.parse(localStorage.getItem(KEY_USER_DATA) as string);

export const removeUserData = (): void =>
  localStorage.removeItem(KEY_USER_DATA);

export const setExpirationToken = (exp: number): void =>
  localStorage.setItem(KEY_EXPIRATION, String(exp));

export const removeExpirationToken = (): void =>
  localStorage.removeItem(KEY_EXPIRATION);

export const expiredToken = (): any => {
  const exp = localStorage.getItem(KEY_EXPIRATION);

  if (exp) {
    const expiredDate = fromUnixTime(parseInt(exp, 10));

    return isBefore(expiredDate, new Date());
  }

  return false;
};

export const setNotification = (data: any): void =>
  localStorage.setItem(KEY_NOTIFICATION, JSON.stringify(data));

export const getNotification = (): void =>
  JSON.parse(localStorage.getItem(KEY_NOTIFICATION) as string);

export const removeNotification = (): void =>
  localStorage.removeItem(KEY_NOTIFICATION);
